import { FormCard } from "core/cards/formCard";
import { EditTextField } from "core/components/inputWithAccessControl";
import { Modal } from "core/components/modal";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import Joi from "joi";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { useAxiosErrorHandlingHook } from "features/errorDisplay/axiosErrorHandlingHook";
import { addGateway } from "./gatewayCrudService";
import { editGateway } from "./gatewayCrudService";

export const GatewayForm = ({ open, onClose, selectedData, onUpdateSuccess }) => {

  const { showError } = useAxiosErrorHandlingHook();
  const { loaderContainer, startLoading, stopLoading } = useLoading();

  const schema = Joi.object({
    id: Joi.number().label("Id").positive().integer().allow(""),
    name: Joi.string().trim().label("Name").required(),
    zoneId: Joi.number().label("Zone Id").positive().required(),
    latitude: Joi.number().label("Latitude").required(),
    longitude: Joi.number().label("Longitude").required(),

  });
  const accessToEdit = true;
  const submitCallback = async (event) => {
    const {
      id,
      name,
      zoneId,
      latitude,
      longitude
    } = inputs;
    try {
      startLoading();

      const body = {
        id,
        name,
        zoneId,
        latitude,
        longitude

      };

      if (
        id === null ||
        id === undefined ||
        id === "" ||
        id === "0" ||
        id === 0
      ) {
        const { data: gateway } = await addGateway(body);
        onUpdateSuccess(gateway);
        toast.success("Gateway Added Successfully");
      } else {
        const { data: gateway } = await editGateway(id, body);
        onUpdateSuccess(gateway);
        toast.success("Gateway Edited Successfully");
      }
      stopLoading();
    } catch (error) {
      console.log(error);
      const toastMessage = "Error adding / editing gateway details";
      showError(error, toastMessage);

      stopLoading();
    }
    onClose();
  };
  let defaultInputs = { id: 0 };
  useEffect(() => {
    if (!_.isEmpty(selectedData)) {
      // console.log(selectedData);
      handleInputChange("id", selectedData["id"]);
      handleInputChange("name", selectedData["name"]);
      handleInputChange("zoneId", selectedData["zoneId"]);
      handleInputChange("longitude", selectedData["longitude"]);
      handleInputChange("latitude", selectedData["latitude"]);

    } else {
      resetInput();
      handleInputChange("id", "");
    }
  }, [selectedData]);

  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    resetInput,
    additionalValidation,
  } = useSubmitForm(schema, submitCallback, defaultInputs);

  const FormHtml = (
    <FormCard
      formName={"Add/Update Zone"}
      onSubmit={handleSubmit}
      onFormReset={resetInput}
      submitAccess={accessToEdit}
    >
      <div className="columns is-multiline">
        <div className="column is-one-third">
          <EditTextField
            identifier="id"
            labelName="Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            required={false}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="name"
            labelName="Name"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="zoneId"
            labelName="Zone Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="latitude"
            labelName="Latitude"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="longitude"
            labelName="Longitude"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
      </div>
    </FormCard>
  );

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div>{FormHtml}</div>
      {loaderContainer}
    </Modal>
  );
};
